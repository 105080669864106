.live-project-selector {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.live-project-selector__title {
  font-weight: bold;
  padding: 0 16px;
}
@media screen and (min-width: 1024px) {
  .live-project-selector__title {
    font-size: 1.125rem;
    padding: 8px 16px;
  }
}
.live-project-selector__projects-list {
  height: 100%;
  overflow-y: scroll;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}
.live-project-selector__actions {
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}
.live-project-selector__projects-list__item {
  cursor: pointer;
  width: 100%;
  margin: 4px 0;
  color: white;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--ion-color-primary);
  min-height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
}
.live-project-selector__projects-list__item--active {
  background-color: var(--ion-color-primary);
}
.live-project-selector__projects-list__item__logo {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--ion-color-primary);
  padding: 2px;
  margin-right: 8px;
}
.live-project-selector__projects-list__item__logo ion-img {
  width: 100%;
  height: 100%;
}
