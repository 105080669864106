.counter {
    text-align: center;
}

.counter__header {
  text-wrap: nowrap;
}

.counter__header__pin {
    display: inline-block;
    height: 1.5rem;
    margin-left: 4px;
    margin-bottom: -4px;
}

.counter__header__label {
    display: inline-block;
    font-size: 20px;
    margin: 0;
}

.counter__header__label--alt {
    font-size: 20px;
    text-align: center;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}

.counter__value,
.counter__value--large {
    font-size: 32px;
    font-weight: bold;
    margin: 0.5rem;
    text-align: center;
}

.counter__value--large {
    font-size: 42px;
}

.counter--with-pin .counter__value {
    text-align: left;
}