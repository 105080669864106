.live-no-stream-progression {
  width: 100%;
  height: 30vh;
  /* background-color: black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .live-no-stream-progression {
    width: 100%;
    height: calc(100vh - 136px);
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.live-no-stream-progression__amount-progress__item {
  width: 128px;
  height: 128px;
  background-color: var(--ion-background-color-light);
  border-radius: 100%;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
  padding: 2px;
  transition: all 150ms ease-in-out;
}
.live-no-stream-progression__amount-progress__item--animate-on {
  transform: scale3d(1.1, 1.1, 1.1);
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.4);
}
