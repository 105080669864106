.show-detail-modal {
  height: 100%;
  background-size: cover;
  background-position: center;
  color: white;
}
.show-detail-modal__content {
  height: calc(100% - 96px);
  width: 100%;
  background: linear-gradient(
    transparent,
    #00000060,
    #00000099,
    #000000f9,
    black,
    black,
    black,
    black
  );
  overflow-y: auto;
}
.show-detail-modal__header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.show-detail-modal__header__close {
  font-size: 32px;
  cursor: pointer;
}
.show-detail-modal__content__inner {
  padding: 150px 16px 16px 16px;
}
.show-detail-modal__content__inner__infos {
  margin-bottom: 32px;
}
.show-detail-modal__content__inner__action {
  margin-bottom: 32px;
}
.show-detail-modal--join-form-mode {
  height: 100%;
}
ion-backdrop {
  pointer-events: none;
}
