.multiplex-jauge__title {
  max-width: 380px;
  font-size: 22px;
  font-weight: bold;
  margin: 0.5rem 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.multiplex-jauge__title img {
  max-height: 100px;
}

.multiplex-jauge__progress-bar {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: -15px 2rem 0;
    font-size: 18px;
    height: 100%;
    justify-content: center;
    width: 85%;
}

.multiplex-jauge__progress-bar__legend {
    display: flex;
    justify-content: space-between;
}

.multiplex-jauge__progress-bar__progress {
    font-weight: bold;
    color: #FF4700;
}

.multiplex-jauge__progress-bar__location {
    font-weight: bold;
}

.multiplex-jauge__counter {
    text-align: center;
}

.multiplex-jauge__counter-label {
    font-size: 20px;
}

.multiplex-jauge__counter-state {
    font-weight: bold;
    font-size: 40px;
    margin: 4px 0;
}