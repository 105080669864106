.dashed-bar__container {
    display: flex;
    width: 100%;
    align-items: flex-end;
}

.dashed-bar__icon {
    display: flex;
    width: 4rem;
    height: 2.8rem;
    justify-content: center;
    padding-bottom: 4px;
    flex-shrink: 0;
}

.dashed-bar__icon img {
    height: 2.8rem;
}

.dashed-bar__bar {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 0.5rem;
    text-align: center;
    width: 100%;
    height: 5.5rem;
}

.dashed-bar__bar-legend {
    position: relative;
    width: 100%;
}

.dashed-bar__bar-legend__current {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 34px;
    transform: translateX(-50%);
    font-size: 22px;
    font-weight: bold;
    border-radius: 8px;
    background-color: white;
}

.dashed-bar__bar-legend__current-pointer {
    position: absolute;
    bottom: -2.5rem;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top-width: 10px;
    border-top-style: solid;
    transform: translateX(-50%);
    border-top-color: white;
}

.dashed-bar__bar-container{
    position: relative;
    background-image: url("../../../assets/icon/Line-empty.svg");
    width: 100%;
    margin-top: 3rem;
    background-repeat: repeat;
    background-size: contain;
    height: 40px;
}

.dashed-bar__bar-filled {
    height: 100%;
    background-image: url("../../../assets/icon/Line-filled.svg");
    background-repeat: repeat;
    background-size: contain;
}