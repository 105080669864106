.live-banner {
  height: 56px;
  width: 100%;
  background-color: var(--ion-background-color);
}
.live-banner__event-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
.live-banner__event-logo ion-img {
  height: 48px;
  width: 48px;
  border: 1px solid var(--ion-color-primary);
  padding: 4px;
  border-radius: 4px;
  background-color: white;
}
