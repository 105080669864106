.direct-join-page ion-content {
  --overflow: hidden;
}
.direct-join-page__main {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0.1;
}
