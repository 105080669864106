.live-banner-project {
  width: 100%;
  height: 100%;
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}
.live-banner-project__logo {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.live-banner-project__amount-progress {
  flex: 1;
  display: flex;
  justify-content: center;
}

.live-banner-project__goal {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}

.live-banner-project__logo__item {
  width: 48px;
  height: 48px;
  background-color: var(--ion-color-dark-shade);
  border-radius: 4px;
  border: 1px solid var(--ion-color-primary);
  padding: 2px;
  background-color: white;
}
.live-banner-project__logo__item ion-img {
  width: 100%;
  height: 100%;
}
.live-banner-project__amount-progress__item {
  width: 64px;
  height: 64px;
  background-color: var(--ion-background-color-light);
  position: relative;
  top: 8px;
  border-radius: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  padding: 2px;
  transition: all 150ms ease-in-out;
}
.live-banner-project__amount-progress__item--animate-on {
  transform: scale3d(1.1, 1.1, 1.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
}
.live-banner-project__goal__display {
  width: 100%;
  height: 36px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.live-banner-project__goal__display span:first-of-type {
  font-weight: bold;
}
.live-banner-project__goal__display span:last-of-type {
  font-weight: bold;
  font-size: 12px;

  color: var(--ion-color-success);
}
.animate-amount-off {
  color: white;
  transition: all 150ms ease-in-out;
}
.animate-amount-on {
  transition: all 150ms ease-in-out;
  transform: scale3d(1.1, 1.1, 1.1);
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
}
