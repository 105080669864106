.multiplex-end__main {
    width: 100vw;
    min-width: fit-content;
    min-height: 100vh;
    font-family: 'Lexend Deca', sans-serif;
    text-align: left;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.multiplex-end__main__multiplex-card {
    margin: 2rem;
}

.multiplex-end__main__multiplex-card__infos img {
    width: auto;
    max-height: 100px;
    margin: auto;
}

.multiplex-end__main__multiplex-tracker {
    position: relative;
    padding: 0.5rem;
    margin: 2rem;
}

.multiplex-end__main__multiplex-tracker__content {
    position: relative;
    z-index: 2;
}

.multiplex-end__multiplex-tracker__heart {
    position: absolute;
    height: 106px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.multiplex-end__show-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    max-width: 1520px;
    margin: 2rem auto;
}