.show-card {
  position: relative;
  width: 100%;
  background-size: cover;
  box-shadow: var(--base-card-shadow);
  border-radius: 8px;
  cursor: pointer;
}
.show-card:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.show-card__content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  background: linear-gradient(transparent, #00000060, #00000099, #000000f9);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.show-card__content__cal-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.show-card_content_infos-container {
  width: 100%;
}
