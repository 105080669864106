.home-header {
  background-color: var(--ion-background-color);
}
.home-header__content {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
ion-toolbar {
  background-color: var(--ion-background-color);
  --padding-start: 0;
  --padding-end: 0;
}
