.live-banner-countdown {
  height: 100%;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
