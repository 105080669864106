.multiplex-summary__main {
    width: 100vw;
    min-height: 100vh;
    font-family: 'Lexend Deca', sans-serif;
    text-align: left;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: fit-content;
}

.multiplex-summary__main__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.multiplex-summary__main__multiplex-infos {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
}

.multiplex-summary__main__multiplex-infos img {
    width: auto;
    max-height: 100px;
}

.multiplex-summary__main__global {
    position: relative;
    padding: 0.5rem;
    margin: 2rem 0;
    min-width: 120px;
}
.multiplex-summary__main__global__content {
    position: relative;
    z-index: 2;
}

.multiplex-summary__main__global__content .counter__value--large {
    font-size: 32px;
}

.multiplex-summary__main__global__heart {
    position: absolute;
    height: 80px;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.multiplex-summary__main__dashboard-header {
    font-size: 24px;
    font-weight: bold;
    margin: 1rem;
}

.multiplex-summary__main__dashboard-header img {
    max-height: 200px;
}

.multiplex-summary__main__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 1rem;
}

.multiplex-summary__main__current-project {
    display: flex;
    flex-grow: 1;
}
.multiplex-summary__main__current-project__card {
    margin: auto;
}
.multiplex-summary__main__current-project .divider {
    height: auto;
}

.multiplex-summary__main__location-pile {
    padding: 0.3rem 1.5rem;
    border-radius: 25px;
    width: fit-content;
    margin: 0 auto 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 24px;
    font-weight: bold;
}

.multiplex-summary__main__location-pile svg,
.multiplex-summary__main__highlight-location svg {
    height: 28px;
}

.multiplex-summary__main__highlight-project {
    flex-grow: 2;
    min-height: 160px;
}

.multiplex-summary__main__dashboard-count {
    flex-grow: 2;
}

.multiplex-summary__main__counter-list {
    display: flex;
    justify-content: space-evenly;
}

.multiplex-summary__main__dashboard-count__title {
    font-size: 20px;
    text-transform: uppercase;
}

.multiplex-summary__main__dashboard-count__count {
    font-size: 42px;
    margin: 0.6rem 0;
}

.multiplex-summary__main__dashboard-count__goal {
    font-size: 20px;
}

.multiplex-summary__main .multiplex__container {
    padding: 2rem;
    height: 100%;
    margin: 0;
}