.home-shows-grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1);
  grid-gap: 1rem;
  padding: 1rem 0;
}
@media screen and (min-width: 640px) {
  .home-shows-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, calc((100% - 1rem) / 2));
    grid-gap: 1rem;
    padding: 1rem 0;
  }
}
@media screen and (min-width: 1024px) {
  .home-shows-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 2rem) / 3));
    grid-gap: 1rem;
    padding: 1rem 0;
  }
}
@media screen and (min-width: 1280px) {
  .home-shows-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, calc((100% - 3rem) / 4));
    grid-gap: 1rem;
    padding: 1rem 0;
  }
}
@media screen and (min-width: 1900px) {
  .home-shows-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, calc((100% - 4rem) / 5));
    grid-gap: 1rem;
    padding: 1rem 0;
  }
}
