.live-top-panel {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--ion-background-color-light);
  top: calc((100% - 32px) * -1);
  transition: all 300ms ease-in-out;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .live-top-panel {
    max-width: 360px;
    right: 0;
  }
}
.live-top-panel--is-active {
  top: -16px;
  padding-top: 16px;
  justify-content: flex-start;
}
.live-top-panel__unactive-display {
  width: 100%;
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.live-top-panel__unactive-display__hamburger {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
.live-top-panel__unactive-display__hamburger div {
  width: 24px;
  height: 3px;
  background-color: var(--ion-color-primary);
  border-radius: 16px;
}
.live-top-panel__unactive-display__user-amount {
  font-weight: bold;
  color: var(--ion-color-primary);
}
.live-top-panel--is-active .live-top-panel__unactive-display {
  display: none;
}
.live-top-panel__active-display {
  padding: 16px;
  width: 100%;
  height: 100%;
}
.live-top-panel__active-display__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--ion-color-dark-tint);
  margin-bottom: 8px;
}
.live-top-panel__active-display__header span:first-of-type {
  font-size: 18px;
}
.live-top-panel__active-display__header span:last-of-type {
  color: var(--ion-color-primary);
  font-size: 32px;
  line-height: 32px;
  max-height: 32px;
  cursor: pointer;
}

.live-top-panel__active-display__pledges-list {
  height: calc(100% - 124px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.live-top-panel__active-display__footer__pledges-total {
  padding: 8px 0;
  border-bottom: 1px solid var(--ion-color-primary);
  border-top: 1px solid var(--ion-color-primary);
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}
.live-top-panel__active-display__footer__pledges-total span:last-of-type {
  font-weight: bold;
}
.live-top-panel--is-unactive .live-top-panel__active-display {
  display: none;
}
.live-top-panel__active-display__pledges-list__item {
  width: 100%;
  margin: 4px 0;
  color: white;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--ion-color-primary);
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.live-top-panel__active-display__pledges-list__item__infos {
  display: flex;
  align-items: center;
}
.live-top-panel__active-display__pledges-list__item__infos__logo {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: var(--ion-color-dark-shade);
  border-radius: 4px;
  border: 1px solid var(--ion-color-primary);
  padding: 2px;
  margin-right: 8px;
  background-color: white;
}
.live-top-panel__active-display__pledges-list__item__infos__logo ion-img {
  width: 100%;
  height: 100%;
}
.live-top-panel__active-display__pledges-list__item__amount {
  font-weight: bold;
}
