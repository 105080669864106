.multiplex__container {
    position: relative;
    padding: 3rem 2rem;
    margin-bottom: 1rem;
    min-width: fit-content;
}

.multiplex__container__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 10px;
    opacity: 0.3;
}

.multiplex__container__content {
    position: relative;
    z-index: 2;
}