.live-main-window {
  height: 100%;
  width: 100%;
  padding-top: 32px;
}
@media screen and (min-width: 1024px) {
  .live-main-window {
    padding-top: 0;
    max-width: calc(100% - 360px);
  }
}
