.multiplex-overview__main {
    position: relative;
    width: 100vw;
    height: 100vh;
    font-family: 'Lexend Deca', sans-serif;
}

.multiplex-overview__main .bottom-overlay__left {
    width: 120px;
}

.multiplex-overview__flux {
    width: 100vw;
    height: 100vh;
    background-color: black;
}

.multiplex-overview__logo {
    width: fit-content;
    max-width: 190px;
    max-height: 80px;
}

.multiplex-overview__logo img {
    max-height: 100%;
    width: auto;
}

.multiplex-overview__counts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 100%;
    width: 100%;
}

.multiplex-overview__label {
    margin-left: 2rem;
    margin-right: auto;
}

.multiplex-overview__name {
    font-size: 28px;
    margin-bottom: 3px;
}

.multiplex-overview__subtitle {
    font-size: 24px;
}

.multiplex-overview__counts .divider {
  margin: 0 1rem;
}

.multiplex-overview__heart-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0 1rem;
}

.multiplex-overview__heart-content {
    z-index: 2;
}

.multiplex-overview__revers-gradient__container {
    position: relative;
    padding: 0.4rem 5rem;
    border-radius: 15px;
    margin: -1.5rem -2rem -1.5rem 0;
}

.multiplex-overview__revers-gradient {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 1rem;
    opacity: 0.8;
}

.multiplex-overview__heart {
    position: absolute;
    height: 106px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.multiplex-overview__container__content {
    position: relative;
    z-index: 2;
    padding: 0.1rem;
}

.multiplex-overview__total {
    position: relative;
    z-index: 2;
}