.counter-page__main__counter {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.counter-page__main__counter__show-total-pledges {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.counter-page__main__counter__show-total-pledges span:first-of-type {
  font-size: 16px;
  margin-bottom: 8px;
}
.counter-page__main__counter__show-total-pledges span:last-of-type {
  font-size: 24px;
  font-weight: bold;
}
.counter-page__main__counter__project-counter {
  width: 100%;
}
.counter-page__main__counter__project-counter__current-project-name {
  display: block;
  font-size: 48px;
  margin-bottom: 32px;
  text-align: center;
}
.counter-page__main__counter__project-counter__logo {
  height: 256px;
    /* width: 128px; */
  margin: auto;
  margin-bottom: 16px;
  border-radius: 4px;
  /* border: 1px solid var(--ion-color-primary); */
  padding: 2px;
    aspect-ratio: 16/9;
}
.counter-page__main__counter__project-counter__logo ion-img {
  width: 100%;
  height: 100%;
}
.counter-page__main__counter__project-counter__amount {
  /* border: 1px solid var(--ion-color-primary); */
  text-align: center;
  font-size: 80px;
  border-radius: 8px;
  max-width: 600px;
  margin: auto;
}
.counter-page__main__counter__project-counter__progress-bar {
  width: 100%;
  max-width: 1440px;
  padding: 0 64px;
}
.counter-page__main__counter__project-counter__progress-bar__bar {
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 90px;
  overflow: hidden;
}
.counter-page__main__counter__project-counter__progress-bar__bar__inner {
  width: 100%;
  height: 100%;
  background-color: var(--ion-color-primary);
  transition: transform 300ms ease-in-out;
  transform: translateX(-100%);
}
.counter-page__main__counter__project-counter__progress-bar__percentage {
  font-size: 32px;
  margin-bottom: 8px;
}
.counter-page__main__counter__project-counter__progress-bar__percentage div {
  transition: transform 300ms ease-in-out;
}
.counter-page__main__counter__project-counter__progress-bar__target {
  display: flex;
  justify-content: space-between;
  font-size: 32px;
  margin-top: 8px;
  transition: transform 300ms ease-in-out;
}
.counter-page__main__counter__project-counter__progress-bar__target
  span:last-of-type {
  color: #2ca795;
}

.counter-page__main__counter__project-counter__goal {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    font-size: 22px;
}

.counter-page__main__counter__project-counter__goal span {

}