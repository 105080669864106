.join-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  color: white;
}
.join-form__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 112px;
}
.join-form__header__spacer {
  width: 32px;
}
.join-form__header__show-logo {
  width: 96px;
  height: 96px;
  border: 2px solid var(--ion-color-primary);
  padding: 4px;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 32px;
}
.join-form__header__close {
  font-size: 32px;
  cursor: pointer;
}
.join-form__title {
  font-weight: bold;
  font-size: 20px;
  height: 40px;
  margin-top: 32px;
}
.join-form__form {
  background-color: var(--ion-background-color);
  width: 100%;
  height: calc(100vh - 112px - 56px - 128px);
  border-radius: 8px;
  overflow-y: auto;
  padding: 8px;
  overflow-x: hidden;
}
.join-form__form__checkbox-input {
  margin: 16px 0 0 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--ion-color-medium);
  border-radius: 4px;
}
.join-form__form__checkbox-input input[type="checkbox"] {
  cursor: pointer;
  height: 24px;
  width: 24px;
}
@media screen and (min-width: 768px) {
  .join-form__form {
    height: calc(500px - 112px - 56px - 128px);
  }
}
@media screen and (min-height: 768px) and (min-width: 768px) {
  .join-form__form {
    height: calc(600px - 112px - 56px - 128px);
  }
}
.join-form__controls {
  min-height: 112px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0;
}
.join-form__controls__ask-share-infos {
  font-size: 12px;
  display: flex;
  margin-bottom: 8px;
}
.join-form__form__on-place-ask {
  width: 100%;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
}
.join-form__form__on-place-ask__button {
  width: calc(50% - 8px);
  opacity: 0.2;
}
.join-form__form__on-place-ask__button--active {
  opacity: 1;
}
.join-form__form__receipt-ask {
  width: 100%;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
}
.join-form__form__receipt-ask__button {
  width: calc(50% - 8px);
  opacity: 0.2;
}
.join-form__form__receipt-ask__button--active {
  opacity: 1;
}
.join-form__controls__buttons {
  display: flex;
  justify-content: flex-end;
}
.join-form__controls__ask-share-infos {
  display: flex;
  align-items: center;
  padding: 0 8px;
}
.join-form__controls__ask-share-infos__label {
  margin-left: 16px;
}
