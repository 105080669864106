.home-page__main {
  padding: 1rem;
}
.home-page__main__title {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 1rem;
}
