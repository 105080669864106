.show-ending-modal {
  padding: 48px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.show-ending-modal__show-infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.show-ending-modal__show-infos__logo {
  width: 128px;
  height: 128px;
  border: 2px solid var(--ion-color-primary);
  padding: 4px;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 32px;
}
.show-ending-modal__show-infos span {
  font-weight: bold;
}
.show-ending-modal__title {
  font-size: 1.5rem;
  font-weight: bold;
}
.show-ending-modal__show-infos__name {
  font-size: 28px;
}
.show-ending-modal__text {
  text-align: center;
  font-weight: bold;
  white-space: pre-wrap;
}
.show-ending-modal__after-link {
  width: 100%;
}
