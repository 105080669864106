.live-chat {
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* background-color: aqua; */
  padding: 8px 0;
}
.live-chat__messages-zone {
  height: calc(100% - 56px);
  min-height: calc(100% - 56px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  margin-bottom: 8px;
}
.live-chat__messages-zone__item {
  padding: 8px;
  border: 1px solid var(--ion-color-primary);
  margin: 4px 0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  white-space: pre-wrap;
}
.live-chat__messages-zone__item span:first-of-type {
  color: var(--ion-color-secondary);
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 4px;
  text-transform: capitalize;
}
.live-chat__messages-zone__item:first-of-type {
  margin-top: auto;
}
.live-chat__messages-zone__item:last-of-type {
  margin-bottom: 0;
}
.live-chat__messages-zone__item__username--admin {
  color: var(--ion-color-tertiary-tint) !important;
}
/*
Glow effect might not be performant
*/
@keyframes glow {
  0% {
    box-shadow: 0 0 4px 0px rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 8px 0px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 4px 0px rgba(255, 255, 255, 0.5);
  }
}
.live-chat__messages-zone__item--has-pledge {
  background-color: rgba(0, 0, 0, 0.2);

  box-shadow: 0 0 4px 0px rgba(255, 255, 255, 0.5);
  border-color: #aea884;
}
.live-chat__messages-zone__item--has-pledge {
  animation: glow 750ms ease infinite 500ms;
}
/* .live-chat__messages-zone__item--has-pledge {
  animation: glow 1s linear infinite;
}
.live-chat__messages-zone__item--has-pledge:last-of-type {
  animation: glow 1s linear infinite;
} */
.live-chat__messages-zone__item--has-pledge span:first-of-type {
  color: #dad3ab;
  font-weight: bold;
  font-size: 16px;
}
.live-chat__messages-zone__item--has-pledge span:last-of-type {
  color: white;
  font-size: 20px;
}
.live-chat__writing-zone {
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.live-chat__writing-zone__input-container {
  width: 100%;
}
.live-chat__writing-zone__send-btn {
  width: 56px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border-radius: 8px;
  margin-left: 8px;
}
