.live-bottom-panel {
  position: absolute;
  height: calc(100% - 32px - 56px - 30vh - 64px - 18px);
  width: 100%;
  bottom: 0;
  background-color: var(--ion-background-color-light);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.3);
  transition: all 150ms ease;
}
.live-bottom-panel--pledge-mode {
  height: calc(100% - 32px - 56px - 30vh - 18px);
}
@media screen and (min-width: 1024px) {
  .live-bottom-panel {
    max-width: 360px;
    right: 0;
    height: 100%;
    padding-top: 32px;
  }
  .live-bottom-panel--pledge-mode {
    height: 100%;
  }
}
.fade-panel-enter {
  opacity: 0;
}
.fade-panel-exit {
  opacity: 1;
}
.fade-panel-enter-active {
  opacity: 1;
}
.fade-panel-exit-active {
  opacity: 0;
}
.fade-panel-enter-active,
.fade-panel-exit-active {
  transition: opacity 300ms;
}
