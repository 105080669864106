.live-pledge-confirmator {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.live-pledge-confirmator__amount-confirmation {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.live-pledge-confirmator__amount-confirmation span:last-of-type {
  font-size: 24px;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.live-pledge-confirmator__free-amount__label {
  font-weight: bold;
}
.live-pledge-confirmator__free-amount__amount--desktop {
  display: none;
}
@media screen and (min-width: 1024px) {
  .live-pledge-confirmator {
    justify-content: flex-end;
  }
  .live-pledge-confirmator__free-amount {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 16px;
  }
  .live-pledge-confirmator__free-amount__label {
    font-size: 1.25rem;
    padding: 8px 0;
  }
  .live-pledge-confirmator__amount-confirmation span:first-of-type {
    font-size: 1.25rem;
    padding: 8px 0;
  }
  .live-pledge-confirmator__amount-confirmation span:last-of-type {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
  }
  .live-pledge-confirmator__free-amount__amount--desktop {
    display: block;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
  }
}
.live-pledge-confirmator__free-amount__input-container {
  margin-top: 32px;
}
.live-pledge-confirmator__public-pledge-asker {
  display: flex;
  align-items: center;
  margin: 32px 0;
}
.live-pledge-confirmator__public-pledge-asker__toggle-label {
  padding-left: 8px;
  font-size: 12px;
}
.live-pledge-confirmator__actions {
  display: flex;
  justify-content: flex-end;
}
