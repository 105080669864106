@media screen and (min-width: 1024px) {
  .home-segments {
    display: flex;
    justify-content: flex-end;
  }
  .home-segments__segments {
    width: 400px;
    margin-right: 0;
  }
}
