* {
  color-scheme: dark;
  -webkit-text-size-adjust: 100%;
  /* Prevent text from adjusting in landscape ios mode */
}

:root {
  --base-card-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);

  --ion-color-primary: #848aae;
  --ion-color-primary-rgb: 132, 138, 174;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #747999;
  --ion-color-primary-tint: #9096b6;

  --ion-color-secondary: #2ca795;
  --ion-color-secondary-rgb: 44, 167, 149;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #279383;
  --ion-color-secondary-tint: #41b0a0;

  --ion-color-tertiary: #ec4899;
  --ion-color-tertiary-rgb: 236, 72, 153;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #d03f87;
  --ion-color-tertiary-tint: #ee5aa3;

  --ion-color-success: #10b981;
  --ion-color-success-rgb: 16, 185, 129;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #0ea372;
  --ion-color-success-tint: #28c08e;

  --ion-color-warning: #f59e0b;
  --ion-color-warning-rgb: 245, 158, 11;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d88b0a;
  --ion-color-warning-tint: #f6a823;

  --ion-color-danger: #ef4444;
  --ion-color-danger-rgb: 239, 68, 68;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d23c3c;
  --ion-color-danger-tint: #f15757;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #222428;
  --ion-background-color-rgb: 34, 36, 40;
  --ion-background-color-light: #2e2f36;

  --ion-text-color: #f4f5f8;
  --ion-text-color-rgb: 244, 245, 248;

  --ion-color-step-50: #2d2e32;
  --ion-color-step-100: #37393d;
  --ion-color-step-150: #424347;
  --ion-color-step-200: #4c4e52;
  --ion-color-step-250: #57585c;
  --ion-color-step-300: #616366;
  --ion-color-step-350: #6c6d71;
  --ion-color-step-400: #76787b;
  --ion-color-step-450: #818286;
  --ion-color-step-500: #8b8d90;
  --ion-color-step-550: #96979a;
  --ion-color-step-600: #a0a1a5;
  --ion-color-step-650: #abacaf;
  --ion-color-step-700: #b5b6ba;
  --ion-color-step-750: #c0c1c4;
  --ion-color-step-800: #cacbce;
  --ion-color-step-850: #d5d6d9;
  --ion-color-step-900: #dfe0e3;
  --ion-color-step-950: #e9ebee;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  /* body {
    --ion-color-primary: #848aae;
    --ion-color-primary-rgb: 132, 138, 174;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #747999;
    --ion-color-primary-tint: #9096b6;

    --ion-color-secondary: #2ca795;
    --ion-color-secondary-rgb: 44, 167, 149;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #279383;
    --ion-color-secondary-tint: #41b0a0;

    --ion-color-tertiary: #ec4899;
    --ion-color-tertiary-rgb: 236, 72, 153;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #d03f87;
    --ion-color-tertiary-tint: #ee5aa3;

    --ion-color-success: #10b981;
    --ion-color-success-rgb: 16, 185, 129;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #0ea372;
    --ion-color-success-tint: #28c08e;

    --ion-color-warning: #f59e0b;
    --ion-color-warning-rgb: 245, 158, 11;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d88b0a;
    --ion-color-warning-tint: #f6a823;

    --ion-color-danger: #ef4444;
    --ion-color-danger-rgb: 239, 68, 68;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d23c3c;
    --ion-color-danger-tint: #f15757;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-background-color: #222428;
    --ion-background-color-rgb: 34, 36, 40;

    --ion-text-color: #f4f5f8;
    --ion-text-color-rgb: 244, 245, 248;

    --ion-color-step-50: #2d2e32;
    --ion-color-step-100: #37393d;
    --ion-color-step-150: #424347;
    --ion-color-step-200: #4c4e52;
    --ion-color-step-250: #57585c;
    --ion-color-step-300: #616366;
    --ion-color-step-350: #6c6d71;
    --ion-color-step-400: #76787b;
    --ion-color-step-450: #818286;
    --ion-color-step-500: #8b8d90;
    --ion-color-step-550: #96979a;
    --ion-color-step-600: #a0a1a5;
    --ion-color-step-650: #abacaf;
    --ion-color-step-700: #b5b6ba;
    --ion-color-step-750: #c0c1c4;
    --ion-color-step-800: #cacbce;
    --ion-color-step-850: #d5d6d9;
    --ion-color-step-900: #dfe0e3;
    --ion-color-step-950: #e9ebee;
  } */

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  /* .ios body {
    --ion-background-color: #222428;
    --ion-background-color-rgb: 34, 36, 40;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  } */

  /* .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  } */

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  /* .md body {
    --ion-background-color: #222428;
    --ion-background-color-rgb: 34, 36, 40;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  } */
}
