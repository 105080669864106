.live-stream {
  width: 100%;
  height: 30vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.live-stream__youtube-video {
  height: 100%;
}
@media screen and (min-width: 1024px) {
  .live-stream {
    width: 100%;
    height: calc(100vh - 136px);
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
