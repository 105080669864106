.base-toggle {
  cursor: pointer;
  position: relative;
  background-color: var(--ion-color-dark-tint);
  border-radius: 1rem;
  height: 1.25rem;
  min-width: 2.34375rem;
  line-height: 1rem;
  transition: background-color 200ms ease-in-out;
}
.base-toggle__circle {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  background-color: white;
  border: 0.078125rem solid rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
  transition: transform 150ms ease-in-out, border-color 150ms ease-in-out;
}
.base-toggle--active {
  background-color: var(--ion-color-success);
}
.base-toggle--active .base-toggle__circle {
  transform: translate3d(1.171875rem, 0, 0);
  border-color: var(--ion-color-success);
}
/* &.toggled {
  background-color: $color-success;
  &.dt {
    background-color: $color-dt-success;
    .base-toggle_circle {
      border-color: $color-dt-primary;
    }
  }
  .base-toggle_circle {
    transform: translate3d(1.171875rem, 0, 0);
    border-color: $color-success;
  }
} */
