.live-amounts {
  height: 64px;
  width: 100%;
  background-color: var(--ion-background-color);
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  align-items: center;
  padding: 16px;
}
@media screen and (min-width: 1024px) {
  .live-amounts {
    background-color: black;
    justify-content: center;
    overflow-x: auto;
  }
}
.live-amounts__item {
  cursor: pointer;
  border: 1px solid var(--ion-color-primary);
  padding: 8px 16px;
  white-space: nowrap;
  margin: 0 8px;
  border-radius: 8px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.075);
}
.live-amounts__item:first-of-type {
  margin-left: 0;
}
.live-amounts__item:last-of-type {
  margin-right: 0;
}
